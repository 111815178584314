import service from '@/utils/request'

//微信支付二维码
export function wechat_pay(id,data){
    return service({
        url:'api/member_order/pay/'+id,
        method:'post',
        data
    })
}
//微信查询订单支付状态
export function payment_order(params){
    return service({
        url:'api/member_order/pay_result',
        method:'get',
        params
    })
}

//获取会员证
export function getMembershiPcertificate(){
    return service({
        url:'api/member_apply/member-card',
        method:'get',
    })
}